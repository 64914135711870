import "keen-slider/keen-slider.min.css";
import "./src/static/styles/app.scss"

export const onClientEntry = () => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-Q7T7E8QHZG';
      document.head.appendChild(script);
  
      const scriptInline = document.createElement('script');
      scriptInline.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-Q7T7E8QHZG');
      `;
      document.head.appendChild(scriptInline);
    }
  };
  